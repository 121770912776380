.home-bg {
    background: url('../../Assets/Images/bg_counter.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.gradient-gray {
    background: linear-gradient(to bottom, #FFFFFF, var(--light-gray));
}
