
.iphone-wrapper {
    margin-top: -40px;
    width: 20em;
    position: relative;
}

.iphonex {
    width: 20em;
    height: 40em;
    display: flex;
    position: relative;
    transform: scale(0.9);
}

.iphone-shadow {
    box-shadow: 0 2px 38px 0 rgba(0, 0, 0, 0.5), 0 16px 30px 0 rgba(0, 0, 0, 0.1);
}

.iphone-shadow:hover {
    box-shadow: 0 2px 38px 0 rgba(68, 35, 95, 0.6), 0 16px 30px 0 rgba(117, 51, 162, 0.05);
}

.iphone-inner-shadow {
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19) inset;
    border-radius: 3em;
}

.iphonex .front {
    display: flex;
    flex: 1;
    /*Phone border Color*/
    background-color: #eaeaea;
    border-radius: 3em;
    margin: 0.2em;
    overflow: hidden;
    position: relative;
    z-index: 10;
}
.iphonex .screen{
    display: flex;
    flex: 1;
    background-color: black;
    margin: 0.6em;
    border-radius: 2.6em;
    /*Phone screen border color*/
    /*border: solid 0.2em #eaeaea;*/
    position: relative;
    z-index: 10;
}

.iphonex .screen .screen__view{
    display: flex;
    flex: 1;
    border-radius: 2.4em;
    overflow: hidden;
    position: relative;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
}
.iphonex .screen .screen__view:after,.iphonex .screen .screen__view:before{
    content: "";
    position: absolute;
    z-index: 1;
    width: 50em;
    height: 50em;
    border-radius: 50%;
    background: linear-gradient(110deg,var(--dark-purple), var(--light-purple), var(--dark-purple));
    /*background: linear-gradient(110deg,#AB34B2, #D63776, #5D2B8B );*/
    bottom: 0;
}
.iphonex .screen .screen__view:after{
    transform:  translateY(100%) scaleX(1.4);
    opacity: 0.3;
    transition: all ease 0.5s 0.1s;
}
.iphonex .screen:hover .screen__view:after{
    transform:  translateY(10%) scaleX(1.4);
    transition: all ease 0.9s;
}
.iphonex .screen .screen__view:before{
    transform:  translateY(100%) scaleX(1.4);
    opacity: 1;
    z-index: 40;
    transition: all ease 0.6s;
}
.iphonex .screen:hover .screen__view:before{
    transform:  translateY(10%) scaleX(1.4);
    transition: all ease 0.9s 0.2s;
}

.iphonex .screen:hover .screen__view .dribbbleLogo:not(.white){
    transform: translateY(100%) translateZ(1px);
    transition: all ease 1s 0.1s
}

.iphonex .screen .screen__view .hello{
    font-size: 2.5em;
    color: #fff;
    position: absolute;
    z-index: 60;
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all ease 0.3s;
    transform: translateY(40%);
    font-weight: 600;
}
.iphonex .screen:hover .screen__view .hello{
    opacity: 1;
    transform: translateY(0%);
    transition: all ease 0.6s 0.7s;
}
.iphonex .screen .screen__front{
    position: absolute;
    width: 50%;
    /*NOTCH COLOR*/
    background-color: #d8d8d8;
    height: 1.8em;
    border-radius: 0 0 0.9em 0.9em;
    right: 25%;
    top: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.3em;
    box-sizing: border-box;
    margin-top: 0.5em;
    z-index: 999;
}

.iphonex .screen .screen__front::after{
    left:0.4em;
    transform: translateX(-100%);
    border-left: 0;
    border-radius: 0 0.7em 0 0;
}
.iphonex .screen .screen__front::before{
    right:0.4em;
    transform: translateX(100%);
    border-right: 0;
    border-radius: 0.7em 0 0 0;
}
.iphonex .screen .screen__front-speaker{
    background: grey;
    border-radius: 0.2em;
    height: 0.35em;
    width: 28%;
}
.iphonex .screen .screen__front-camera{
    height: 0.35em;
    width: 0.35em;
    background: grey;
    margin-left: .5em;
    border-radius: 50%;
    margin-right: -0.8em;
}
.iphonex .phoneButtons{
    width: 1em;
    height: 6em;
    position: absolute;
    z-index: 2;
    background: #e5e5e5;
    box-shadow: 0 0 0.4em rgba(255, 255, 255, 0.1) inset;
    border-radius: 2px;
}
.iphonex .phoneButtons-right{
    right: 0;
    top: 30%;
}
.iphonex .phoneButtons-left{
    left: 0;
    top: 15%;
    height: 3em;
}
.iphonex .phoneButtons-left2{
    left: 0;
    top: 25%;
    height: 5em;
}
.iphonex .phoneButtons-left3{
    left: 0;
    top: calc( 25% + 6em);
    height: 5em;
}

@media (max-height: 40em){
    body{
        font-size: 70%;
    }
    .iphonex {
        width: 48vh;
        height: 94vh;
    }
    .title .title__svg svg text{
        font-size: 4em;
    }
}