
.portfolio-image {
    width: 100%;
    height: 400px;
    background-size: cover;
    /*filter: grayscale(100);*/
}

.backdrop-custom {
    background: rgba(255,255,255, 0.9);
    width: 100%;
    height: 100%;
    transition: 0.3s all ease-in-out;
    position: absolute;
    z-index: 9;
    opacity: 0;
}

.backdrop-custom:hover {
    opacity: 1;
    transition: 0.3s all ease-in-out;
}

.backdrop-custom:hover ~ .portfolio-image {
    filter: grayscale(0);
}

.portfolio-link {
    color: inherit;
    text-decoration: none;
}

.portfolio-title {
    font-size: 20px!important;
    font-family: "Cera Pro Medium";
}

.palette {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 2px solid white;
    opacity: 1;
}