.header {
    height: 500px;
    background-color: #8C59E2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: -75px;
}

.down-chevron {
    background: var(--white);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 470px;
    left: calc(50% - 25px);
    right: calc(50% - 25px);
    color: var(--dark-purple);
}


.dotted-bg-header-up {
    height: 400px;
    width: 100%;
    position: absolute;
    background: transparent;
    filter: contrast(7);
    --mask: linear-gradient(red, rgba(0, 0, 0, 0));
    transform: rotate(180deg);
    margin-top: 70px;
}

.dotted-bg-header-up::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(white 1px, transparent 0) 0 0/40px 40px space;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    content: "";
}