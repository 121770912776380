.hero {
    width: 100%;
    height: calc(100vh - 65px);
    overflow: hidden;
    background: rgb(117,76,185);
    background: -moz-linear-gradient(0deg, rgba(117,76,185,1) 0%, rgba(79,47,125,1) 17%, rgba(141,89,227,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(117,76,185,1) 0%, rgba(79,47,125,1) 17%, rgba(141,89,227,1) 100%);
    background: linear-gradient(0deg, rgba(117,76,185,1) 0%, rgba(79,47,125,1) 17%, rgba(141,89,227,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#754cb9",endColorstr="#8d59e3",GradientType=1);
}

.text-hero {
    margin-top: -150px;
}

.max-width-hero {
    max-width: 1550px;
    display: flex!important;
    flex-wrap: wrap!important;
    justify-content: center!important;
    text-align: center!important;
}

.call-hero-btn {
    color: rgba(117,76,185,1);
    background: var(--white);
    text-decoration: none;
    padding: 20px;
    transition: 0.2s all ease-in-out;
    border-radius: 2em;
}

.call-hero-btn:hover {
    color: var(--white);
    background: #36205d;
    transition: 0.2s all ease-in-out;
}


@media only screen and (max-width: 600px) {
    .huge-title {
        font-size: 350%;
    }

    .mobile-hidden {
        display: none!important;
    }

    .hero {
        height: calc(100vh - 63px);
    }
}

.scroll-down {
    position: absolute;
    bottom: -15px;
}

.scroll-text {
    writing-mode: vertical-lr;
    color: white;
    transform: rotate(180deg);
}

.dotted-bg-up {
    background: transparent;
    height: 100px;
    width: 500px;
    background-image: radial-gradient(white 1px, transparent 0);
    background-size: 40px 40px;
    background-position: -19px -19px;
    position: absolute;
    margin-top: -420px;
    margin-left: -400px;
}

.dotted-bg-down {
    z-index: -1;
    background: transparent;
    height: 100px;
    width: 500px;
    background-image: radial-gradient(white 1px, transparent 0);
    background-size: 40px 40px;
    background-position: -19px -19px;
    position: absolute;
    margin-top: 450px;
    margin-right: -440px;
}