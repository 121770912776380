.project-holder {
    aspect-ratio: 1 / 0.7;
}








