.footer {
    margin-bottom: 100px;
    margin-top: 100px;
}

.footer-socials {
    text-decoration: none;
    color: inherit;
    font-size: 20px;
    transition: 0.3s all ease-in-out;
}

.footer-socials:hover {
    text-decoration: none;
    color: var(--light-purple);
    font-size: 20px;
    transition: 0.3s all ease-in-out;
}