.bg-navbar {
    background: #8C59E2;
}

.btn-gradient {
    background-image: linear-gradient(var(--dark-purple), var(--light-purple))!important;
    color: var(--white)!important;
    padding: 10px!important;
    transition: 0.3s all ease-in-out;
    border-radius: 2em;
}

.btn-gradient:hover {
    background: #7142ff!important;
    padding: 10px!important;
    transition: 0.3s all ease-in-out;
}

.nav-link {
    font-family: "Cera Pro Medium";
}

.nav-link:hover {
    color: #7142ff!important;
}

/*.navbar-width {*/
/*    max-width: 1400px!important;*/
/*}*/

.nav-link {
    color: var(--white)!important;
}

.nav-link:hover {
    color: var(--light-gray)!important;
}

.nav-link-font {
    font-family: "Cera Pro Medium";
}
