:root {
  --dark-purple: #7142ff;
  --light-purple: #a855f7;
  --lighter-purple: #D3CEFD;
  --white: #ffffff;
  --gray: #828282;
  --light-gray: #F8F8F8;
}

.color-dark-purple {
  color: var(--dark-purple);
}

.color-light-purple {
  color: var(--light-purple);
}

.color-gray {
  color: var(--gray);
}

.bg-light-gray {
  background: var(--light-gray);
}

.gradient-bg-top-bottom-purple {
  background-image: linear-gradient(var(--dark-purple), var(--light-purple));
}

.gradient-bg-left-to-right-purple {
  background-image: linear-gradient(to right, var(--white), var(--lighter-purple));
}

.gradient-text-top-bottom-purple {
  background: -webkit-linear-gradient(var(--dark-purple), var(--light-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

* {
  font-family: "Cera Pro Light";
  line-height: 1.7em;
}

html {
  /*cursor: none;*/
  overflow-x: hidden;
}

.container {
  max-width: 1140px!important;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-64 {
  font-size: 64px;
}

.purple-link {
  color: black;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

.bg-purple {
  background: #8654D8;
}

h1 {
  font-size: 3rem!important;
}

.purple-link:hover {
  color: var(--light-purple);
  transition: 0.3s all ease-in-out;
}

#circularcursor {
  background-color: var(--light-purple);
  height:10px;
  width:10px;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  position: absolute;
  z-index: 1;
}

.mouse-circle {
  position: absolute;
  margin-top: 14px;
  margin-left: 14px;
  background: rgba(168,85,247, 0.3);
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.App {
  text-align: center;
}

@font-face {
  font-family: 'Cera Pro Black';
  src: local('Cera Pro Black'), url(Assets/Fonts/CeraPro/Cera-Pro-Black.otf) format('opentype');
}

.cera-pro-black {
  font-family: 'Cera Pro Black';
}

@font-face {
  font-family: 'Cera Pro Black Italic';
  src: local('Cera Pro Black Italic'), url(Assets/Fonts/CeraPro/Cera-Pro-Black-Italic.otf) format('opentype');
}

.cera-pro-black-italic {
  font-family: 'Cera Pro Black Italic';
}

@font-face {
  font-family: 'Cera Pro Bold';
  src: local('Cera Pro Bold'), url(Assets/Fonts/CeraPro/Cera-Pro-Bold.otf) format('opentype');
}

.cera-pro-bold {
  font-family: 'Cera Pro Bold';
}


@font-face {
  font-family: 'Cera Pro Light';
  src: local('Cera Pro Light'), url(Assets/Fonts/CeraPro/Cera-Pro-Light.otf) format('opentype');
}

.cera-pro-light {
  font-family: 'Cera Pro Light';
}


@font-face {
  font-family: 'Cera Pro Medium';
  src: local('Cera Pro Medium'), url(Assets/Fonts/CeraPro/Cera-Pro-Medium.otf) format('opentype');
}

.cera-pro-medium {
  font-family: 'Cera Pro Medium';
}


@font-face {
  font-family: 'Cera Pro Regulat Italic';
  src: local('Cera Pro Regulat Italic'), url(Assets/Fonts/CeraPro/Cera-Pro-Regular-Italic.otf) format('opentype');
}

.cera-pro-regular-italic {
  font-family: 'Cera Pro Regulat Italic';
}

.text-large {
  font-size: 4em!important;
}

.big-font {
  font-size: 1.2em!important;
}

.action-button {
  display: block;
  margin-bottom: 10px;
  padding-left: 33px;
  padding-right: 33px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: white!important;
  text-decoration: none;
  background: #8654D8;
  margin-left: -23px!important;
  transition: 0.3s all ease-in-out;
  border-radius: 2em;
}

.action-button:hover {
  transform: scale(1.03);
  transition: 0.3s all ease-in-out;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.card {
  transition: 0.3s all ease-in-out;
  border-radius: 20px!important;
}

.card:hover {
  margin-top: -10px;
  transition: 0.3s all ease-in-out;
}

.padding-paper-holder {
  padding: 100px 0 100px 0;
}

.dotted-circle {
  height: 500px;
  width: 500px;
  border: 1px dashed white;
  border-radius: 50%;
}

.custom-input {
  border-radius: 0!important;
  border-color: var(--light-gray)!important;
  background: var(--light-gray)!important;
  border-radius: 1em!important;
  margin-top: 10px;
}

.custom-input:focus {
  border: 1px solid var(--dark-purple)!important;
  box-shadow: none!important;
}

hr {
  color: gray!important;
}

.down-chevron-content {
  background: var(--white);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: -80px;
  left: calc(50% - 25px);
  right: calc(50% - 25px);
  color: var(--dark-purple);
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 51px;
  width: 51px;
  font-size: 27px;
  padding: 10px;
  line-height: 10px;
  color: #8654D8;
  background: none;
  cursor: pointer;
  z-index: 999999999!important;
  border: 2px solid #8654D8;
  border-radius: 50%;
  transition: 0.3s all ease-in-out;
}

.back-to-top:hover {
  transform: scale(1.05);
  transition: 0.3s all ease-in-out;
}

.contact-tag {
  position: fixed;
  left:0;
  bottom: 150px;
  padding: 10px 20px 10px 15px;
  color: white;
  background: #8654D8;
}

.desktop-hidden {
  display: none;
}

@media only screen and (max-width: 600px) {
  .desktop-hidden {
    display: block;
  }
}

