.bullet-container {
    width: 5em;
    height: 5em;
    border-radius: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: 8px solid rgb(215, 209, 255);
    padding-top: 9px;
}