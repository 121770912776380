.greyscale {
    filter: opacity(58%) grayscale(100);
    transition: 0.3s all ease-in-out;
}

.greyscale:hover {
    filter: opacity(100%) grayscale(100);
    transition: 0.3s all ease-in-out;
}

.border-box {
    border: 0.5px solid #dcdcdc;
    height: 250px;
    margin: 0!important;
    padding: 0!important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.border-0-top {
    border-top: none;
}

.border-0-bottom {
    border-bottom: none;
}

.border-0-left {
    border-left: none;
}

.border-0-right {
    border-right: none;
}

